import { SnackbarMessage, useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import type { Toast } from "~/utils/toast.server";

/**
 * Toaster component displaying toast by enqueuing it in the snackbar queue
 * @param props containing
 * toast: the toast object (see its definition in "~/utils/toast.server")
 */
export const Toaster = ({ toast }: { toast?: Toast | null }) => {
  const { enqueueSnackbar } = useSnackbar();
  const displayedToast = useRef({ toastId: "" });
  const { t } = useTranslation();

  // TODO Accessibility : when user presses 'Escape', the snackbar should close

  useEffect(() => {
    if (toast?.id && toast?.id !== displayedToast.current.toastId) {
      displayedToast.current.toastId = toast?.id;
      enqueueSnackbar(
        t(toast?.title, toast?.translationVariables) as SnackbarMessage,
        {
          variant: toast?.type,
        }
      );
    }
  }, [toast?.id]);

  return <></>;
};
